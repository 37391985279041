<template>
  <v-card class="pa-3">
    <v-card-title class="text-h5"> Delete General Journal Entry </v-card-title>
    <v-card-text
      >This will permanently remove the link to the bank transaction and delete
      the journal entry.</v-card-text
    >
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="red"
        :loading="this.loading_delete"
        dark
        elevation="0"
        @click="deleteJournalEntry()"
      >
        <v-icon left small>mdi-delete</v-icon>
        Delete
      </v-btn>
      <v-btn text @click="passCloseDialog"> Cancel </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {
  deleteJournalEntry,
  getJournalEntryData,
} from "../../Accounting/data/external_journal_entries";
export default {
  name: "GeneralJournalEntryRemoveDialog",
  props: ["value", "remove_and_redo_dialog"],
  data() {
    return {
      loading_delete: false,
      journal_entry: null,
    };
  },
  created() {
    this.getJournalEntryData();
  },
  methods: {
    getJournalEntryData,
    deleteJournalEntry,
    passCloseDialog() {
      this.$emit("changeRemoveDialog");
    },
    passKeyChange() {
      this.$emit("closeDialog");
    },
  },
};
</script>
