import db from "../../../components/firebaseInit";
import firebase from "firebase";
import {
    newDateToISO,
    firebaseTimestamp,
    firestoreDocProps,
} from "../../../composables/external";
import { showSnackbar } from "../../../globalActions/index";

export const getJournalEntries = function (value) {
    // Get journal entries from database
    const docRef = db.collection("general_journal_entries");
    docRef.onSnapshot((querySnapshot) => {
        this.journal_entries = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            this.journal_entries.push(data);
        });
        //  break up all the line items into an array of objects
        //  We have an array of deeply nested objects
        //  We want to loop through the array, push each line item to a new array, and flatten the object
        const obj_array = [];
        this.journal_entries.forEach((entry) => {
            entry.line_items.forEach((item) =>
                obj_array.push({
                    journal_entry_id: item.journal_entry_id,
                    entry_amount: item.entry_amount,
                    account_name: item.ledger_account.account_name,
                    account_id: item.ledger_account.account_id,
                    journal_entry_number: entry.journal_entry_number,
                    journal_entry_date: entry.journal_entry_date,
                    //allocation_status: entry.bank_transaction.transaction_number
                })
            );
        });
        this.filtered_entries = obj_array.filter(
            (i) => i.account_id === value
        );
    });
}
// Used to determine journal entry number
export const getJournalEntryCount = function () {
    const entryRef = db
        .collection("general_journal_entry_counter")
        .doc("LVEzU1bhljJ8ZLLz6pC2");
    entryRef.onSnapshot((snapshot) => {
        const journal_entry_count = snapshot.data().journal_entry_count;
        this.journal_entry_number =
            journal_entry_count < 1000
                ? `GJE-${String("00000" + journal_entry_count).slice(-4)}`
                : `GJE-${journal_entry_count}`;
    });
}
// Determine user that created Journal Entry
export const getUser = function () {
    this.journal_entry_created_by_id = firebase.auth().currentUser.uid;
    db.collection("users")
        .where("uid", "==", this.journal_entry_created_by_id)
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                this.journal_entry_created_by_name = doc.data().displayName;
            });
        });
}

//  CREATE & RECONCILE JOURNAL ENTRY
export const createAndReconcileJournalEntry = function () {
    //  1.  Save journal entry to database
    const saveJournalEntry = () => {
        this.loading = true;
        const batch = db.batch();
        const entryRef = db.collection("general_journal_entries").doc();
        const bankTransactionRef = db
            .collection("bank_transactions")
            .doc(this.value.transaction_id);
        //    Create VAT entry
        createVATEntries();
        //    Create entry in firebase
        batch.set(entryRef, {
            ...firestoreDocProps(
                [
                    "journal_entry_number",
                    "reference_number",
                    "supplier",
                    "journal_entry_date",
                    "line_items",
                    "journal_entry_created_by_id",
                    "journal_entry_created_by_name",
                ],
                this
            ),
            journal_entry_id: entryRef.id,
            journal_entry_date_created: newDateToISO(),
            journal_entry_timestamp: firebaseTimestamp(),
            //    Bank transaction info
            bank_transaction: {
                transaction_id: this.value.transaction_id,
                transaction_number: this.value.transaction_number,
            },
            closed_off: false,
        });
        // 2.   Set bank transaction as allocated 
        batch.update(bankTransactionRef, {
            journal_entry: {
                journal_entry_id: entryRef.id,
                journal_entry_number: this.journal_entry_number,
            },
            transaction_allocation_status: `Journal Entry - ${this.journal_entry_number}`,
        });
        batch
            .commit()
            .then(() => {
                this.loading = false;
                showSnackbar("New journal entry added & reconciled successfully");
            })
            .catch((err) => console.log(err))
    }
    //  3.  Create VAT Entries
    const createVATEntries = () => {
        // loop through all line items ()
        this.line_items.forEach((item) => {
            // Determine which VAT ledger to post to
            const ledger_account =
                this.value.transaction_type === "Cash Out"
                    ? {
                        account_code: "9010",
                        account_id: "VQG2QlAbXMcjbWAeDwPK",
                        account_name: "VAT Input",
                    }
                    : {
                        account_code: "9020",
                        account_id: "tN26TJD9AIdH7a6CZrAr",
                        account_name: "VAT Output",
                    };
            const data = {
                ledger_account: ledger_account,
                entry_amount: item.vat_amount,
            };
            this.line_items.push(data);
        });
    }
    //
    const increaseJournalEntryCounter = function () {
        const increment = firebase.firestore.FieldValue.increment(1);
        const increaseCount = firebase
            .firestore()
            .collection("general_journal_entry_counter")
            .doc("LVEzU1bhljJ8ZLLz6pC2");
        increaseCount
            .update({ journal_entry_count: increment })
            .then(() => {
                console.log("Counter incremented");
            })
            .catch((err) => console.log(err));
    }
    //  ACCOUNTING LOGIC
    //  4. Add entry amount of each line item to "Accounting Totals"
    const AddLineAmountsToAccountingTotals = () => {
        const batch = db.batch()
        this.line_items.forEach((item) => {
            //  Update line item amounts for each ledger in "Accounting Totals"
            const entryRef = db.doc(`accounting_totals/${this.journal_entry_date.slice(0, 7)}/ledger_accounts/${item.ledger_account.account_id}`)
            batch.update(entryRef, {
                monthly_total: firebase.firestore.FieldValue.increment(item.entry_amount.toFixed(2))
            })
        })
        batch.commit()
            .then(() => console.log(`${this.journal_entry_date.slice(0, 7)} ledgers updated`))

    }
    //  5.  Evaluate bank transaction type and reduce relevant "Suspense" account
    const reduceSuspenseAccount = () => {
        const suspenseID = (this.value.transaction_type === "Cash Out") ? "44UTKB7Og5aZLcOvaSOl" : "RSAgQsASdXZKhQRwMZUz"
        const entryTotal = this.line_items.reduce((total, item) => {
            return total + item.entry_amount
        }, 0)
        const docRef = db.doc(`accounting_totals/${this.journal_entry_date.slice(0, 7)}/ledger_accounts/${suspenseID}`)
        docRef.update({
            monthly_total: firebase.firestore.FieldValue.increment(-Math.abs(entryTotal).toFixed(2))
        })
            .then(() => console.log(`Suspense Account updated by ${entryTotal}`))
    }
    //  CALL FUNCTIONS
    //  Check if all line items are assigned to ledgers && Check for ZERO amounts
    if (
        (this.$refs.form.validate() &&
            this.line_items.some((item) => item.ledger_account === null)) ||
        this.line_items.some((item) => item.line_amount === 0)
    ) {
        this.general_journal_entry_validate_dialog = true;
        this.validation_message =
            "Assign each line to a ledger and make sure the 'Total Amount' field doesn't equal Zero";
    } else {
        // 2: Set bank transaction as "Allocated"
        saveJournalEntry();
        // 3: increase journal entry # counter
        increaseJournalEntryCounter();
        //  4. Add entry amount of each line item to "Accounting Totals"
        AddLineAmountsToAccountingTotals();
        //  5. Reduce "Suspense" account
        reduceSuspenseAccount();
        //  6: Close "Create" modal
        this.closeDialog();
    }
}


// DELETE JOURNAL ENTRY
// 1.   Get journal entry data from database
export const getJournalEntryData = function () {
    const journalEntryID = this.value.journal_entry.journal_entry_id;
    const docRef = db.collection("general_journal_entries").where("journal_entry_id", "==", journalEntryID).limit(1);
    docRef.get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                this.journal_entry = doc.data()
                console.log(this.journal_entry.journal_entry_number)
            })
        })
        .catch((error) => error)
}

export const deleteJournalEntry = function () {
    this.loading_delete = true;
    const promises = [];
    const journalEntryID = this.value.journal_entry.journal_entry_id;
    //  ACCOUNTING LOGIC
    const removeLedgerAmounts = () => {
        this.journal_entry.line_items.forEach((item) => {
            const ledgerDocRef = db.collection("accounting_totals")
                .doc(this.journal_entry.journal_entry_date.slice(0, 7))
                .collection("ledger_accounts")
                .doc(item.ledger_account.account_id)
            // Get ledger monthly total from "Accounting Totals"
            promises.push(
                db.runTransaction((transaction) => {
                    return transaction.get(ledgerDocRef)
                        .then((doc) => {
                            // Prevents rounding issues that occur when using "increment"
                            const oldMonthlyTotal = doc.data().monthly_total.toFixed(4);
                            const newMonthlyTotal = (oldMonthlyTotal - Math.abs(item.entry_amount).toFixed(4));
                            transaction.update(ledgerDocRef, { monthly_total: newMonthlyTotal });
                        });
                })
                    .then(() => console.log("1. Ledger account reduced"))
                    .catch((error) => error)
            );
        })
    }
    // 2.   Add amount back to Suspense account
    const addSuspenseAmount = () => {
        const suspenseID = (this.value.transaction_type === "Cash Out") ? "44UTKB7Og5aZLcOvaSOl" : "RSAgQsASdXZKhQRwMZUz"
        const entryTotal = this.journal_entry.line_items.reduce((total, item) => {
            return total + item.entry_amount
        }, 0)
        const suspenseRef = db.collection("accounting_totals")
            .doc(this.journal_entry.journal_entry_date.slice(0, 7))
            .collection("ledger_accounts")
            .doc(suspenseID)
        promises.push(
            suspenseRef.update({
                monthly_total: firebase.firestore.FieldValue.increment(Math.abs(entryTotal).toFixed(4))
            })
                .then(() => console.log("2. Suspense account updated"))
                .catch((error) => error)
        );
    }
    // 3.   Remove journal entry fields from bank transaction
    const removeLinkToBankTransaction = () => {
        const bankRef = db
            .collection("bank_transactions")
            .doc(this.value.transaction_id);
        promises.push(
            bankRef.update({
                journal_entry: firebase.firestore.FieldValue.delete(),
                transaction_allocation_status: "No",
            })
                .then(() => console.log("3. Link to bank transaction removed"))
                .catch((error) => error)
        );
    }
    // 4.   Delete journal entry document
    const deleteEntryDoc = () => {
        promises.push(
            db.doc(`general_journal_entries/${journalEntryID}`)
                .delete()
                .then(() => console.log("4. Journal entry deleted"))
                .catch((error) => error)
        );
    }
    removeLedgerAmounts();
    addSuspenseAmount();
    removeLinkToBankTransaction();
    deleteEntryDoc();

    promises.reduce((accumulatorPromise, nextPromise) => {
        return accumulatorPromise.then(() => {
            return nextPromise;
        });
    }, Promise.resolve());

    this.loading_delete = false;
    showSnackbar("Journal entry deleted");
    this.passKeyChange();
}